import React, { useState } from "react";
import "./Navbar.css";
import { GiHamburgerMenu } from "react-icons/gi";
import {
  Button,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { FiChevronDown } from "react-icons/fi";
import { Link, NavLink } from "react-router-dom";

const Navbar = () => {
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  return (
    <div style={{ zIndex: "100", width: "100vw" }}>
      <nav className="main-nav">
        {/* 1st logo part  */}
        <div className="logo" style={{ marginLeft: "12vw" }}>
          <Link to="/">
            <Image src="kochlogo.png" alt="kochlogo" height="60px" />
          </Link>
        </div>

        {/* 2nd menu part  */}
        <div
          className={
            showMediaIcons ? "menu-link mobile-menu-link" : "menu-link"
          }
        >
          <ul>
            <li>
              <NavLink
                style={{ fontSize: "16px", fontWeight: "400px" }}
                activeStyle={{ fontWeight: "400px" }}
                to="/"
                exact
              >
                KōCH portal
              </NavLink>
            </li>
            <li>
              <NavLink
                style={{ fontSize: "16px", fontWeight: "400px" }}
                activeStyle={{ fontWeight: "400px" }}
                to="/kochclient"
                exact
              >
                KōCH app
              </NavLink>
            </li>
            <li>
              <Menu>
                <MenuButton
                  style={{
                    border: "none",
                    fontSize: "16px",
                    fontWeight: "400",
                    background: "#fff",
                  }}
                  cursor="pointer"
                  _active={{ fontWeight: "400px" }}
                  as={Button}
                  rightIcon={<Icon as={FiChevronDown} />}
                >
                  Resources
                </MenuButton>
                <MenuList fontSize="16px" cursor="pointer" background="#fff">
                  <MenuItem
                    _hover={{ color: "#000" }}
                    height="4vh"
                    padding="20px 12px"
                    as="a"
                    href="/coachform"
                  >
                    Coach Response Form
                  </MenuItem>
                  <MenuItem
                    _hover={{ color: "#000" }}
                    height="4vh"
                    padding="20px 12px"
                    as="a"
                    href="/clientform"
                  >
                    Client Response Form
                  </MenuItem>
                  <MenuItem
                    _hover={{ color: "#000" }}
                    height="4vh"
                    padding="20px 12px"
                    as="a"
                    href="/coach-tutorial"
                  >
                    Coach Tutorial
                  </MenuItem>
                  <MenuItem
                    _hover={{ color: "#000" }}
                    height="4vh"
                    padding="20px 12px"
                    as="a"
                    href="/client-tutorial"
                  >
                    Client App Tutorial
                  </MenuItem>
                </MenuList>
              </Menu>
            </li>
            <li>
              <NavLink
                style={{ fontSize: "16px", fontWeight: "400px" }}
                activeStyle={{ fontWeight: "400px" }}
                to="/about"
                exact
              >
                About Us
              </NavLink>
            </li>
            <li className="try-for-free">
              <a
                href="https://coach.mykoch.app/signup"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <button className="btn-grad"> Sign up for free </button>
              </a>
            </li>
          </ul>
        </div>

        {/* 3rd social media links */}
        <div className="social-media" style={{ marginRight: "12vw" }}>
          {/* hamburget menu start  */}
          <div className="hamburger-menu">
            <a href="#" onClick={() => setShowMediaIcons(!showMediaIcons)}>
              <GiHamburgerMenu />
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
