import React from 'react';
import './PrivacyPolicy.css';
import { Link } from "react-router-dom";

function TermsofServiceComponent() {
  return (
    <>
            <section style={{ paddingBottom: '20vh', paddingLeft: '3vh', paddingRight: '3vh' , justifyContent: 'flex-start' }}>
                <h1 className='mainout' style={{ fontSize: '22px', fontWeight: 600 }}>
                  Website Terms of Service
                    <p style={{ fontSize: '11px', fontWeight: 'normal' }}>Last updated: Jan 20, 2024</p>
                </h1>
                <div style={{ fontSize: '13.5px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>Acceptance of the Terms of Service</h2>
                    <p>These terms of service are entered into by and between you and KoCH Platform Technologies (“Company,” “we,” or “us“). The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, “Terms of Service”), govern your access to and use of <a style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} href="https://www.mykoch.app">www.mykoch.app</a> or <a style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} href='https://coach.mykoch.app'>coach.mykoch.app</a> (the “Website”), including any content, functionality, and services offered on or through the Website, whether as a guest or a registered user.</p>
                    <p>Please read the Terms of Service carefully before using the Website. By using the Website or by clicking to accept or agree to the Terms of Service when this option is made available to you, you accept and agree to be bound and abide by these Terms of Service, our <Link style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} to="/mobile-term-of-service">Mobile Terms of Service</Link> and our <Link style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} to="/privacy-policy">Privacy Policy</Link>, all of which are incorporated herein by reference. If you do not agree to these Terms of Service, our <Link style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} to="/mobile-term-of-service">Mobile Terms of Service</Link>, and/or the <Link style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} to="/privacy-policy">Privacy Policy</Link>, you must not access or use the Website.</p>
                    <p>This Website is offered and available to users of any age. By using this Website, you represent and warrant that you are of legal age to form a binding contract with the Company. Suppose you desire to use this Website from a jurisdiction where you are not of legal age to create a binding contract with the Company. In that case, you must have a parent or guardian contact the Company in advance and provide written consent to use this Website before any such use.</p>
                </div>

                <div style={{ fontSize: '13.5px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>Changes to the Terms of Service</h2>
                    <p>We may revise and update these Terms of Service from time to time at our sole discretion. All changes are effective immediately when we post them and apply to all access to and use of the Website after that</p>
                    <p>Your continued use of the Website after posting the revised Terms of Service means you accept and agree to the changes. You are expected to check this page each time you access this Website to be aware of any changes, as they are binding upon you.</p>
                </div>

                <div style={{ fontSize: '13.5px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>Accessing the Website and Account Security</h2>
                    <p>We reserve the right to withdraw or amend this Website and any service or material we provide on the Website at our sole discretion and without notice. We will not be liable if all or any part of the Website is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Website, or the entire Website, to users, including registered users.</p>
                    <p>You are responsible for both:</p>
                    <ul style={{ paddingLeft: '2%' }}>
                        <li className='liShape'>Make all arrangements necessary for you to have access to the Website.</li>
                        <li className='liShape'>Ensuring that all persons who access the Website through your internet connection are aware of these Terms of Service and comply with them.</li>
                    </ul>
                    <br />
                    <p>You may be asked to provide specific registration details or other information to access the Website or some of its resources. It is a condition of using the Website that all the information you provide is correct, current, and complete. You agree that all information you provide to register with this Website or otherwise, including, but not limited to, through the use of any interactive features on the Website, is governed by our <Link style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} to="/privacy-policy">Privacy Policy</Link>, and you consent to all actions we take concerning your information under our <Link style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} to="/privacy-policy">Privacy Policy</Link>.</p>
                    <p>Suppose you choose or are provided with a username, password, or any other information as part of our security procedures. In that case, you must treat such information as confidential and not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person access to this Website or any part of it using your username, password, or additional security information. You agree to notify us immediately of any unauthorized access to or use of your username, password, or any other security breach. You also agree to exit your account at each session’s end. You should use caution when accessing your account from a public or shared computer so that others cannot view or record your password or other personal information.</p>
                    <p>We have the right to disable any user name, password, or another identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms of Service.</p>
                </div>

                <div style={{ fontSize: '13.5px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>Terms of Sale</h2>
                    <p><b>Trainers.</b> The service that we offer to trainers (the “Trainer Service”) is a website and mobile platform that allows you to build, deliver and monitor training programs for your clients on an individual package/service-bought basis.</p>
                    <ul style={{ paddingLeft: '2%' }}>
                        <li className='liShape'>Each account is organized as a licensed workspace (<b>“Workspace”</b>) for billing purposes, with the number of seats for trainers and clients determined by the invite sent by the trainer. Each Workspace has one owner (which starts as the first user). The owner may invite other users to join as trainers in the Workspace depending upon his capability of trainer seats. Each of these users will have account-level permissions as determined by the owner. Existing trainers or clients can simply be deactivated to free up trainer or client seats if they are not using the packages/services provided by the coaches.</li>
                        <li className='liShape'>Please note that pricing and features are subject to future changes in our discretion and that software updates may also impact future services offered.</li>
                        <li className='liShape'>Fees and Payments:
                          <ul style={{ paddingLeft: '2%' }}>
                            <li style={{ listStyleType: 'circle' }}>An account holder can buy KoCH's services based on a requirement according to the client's requirements, which the trainer can identify for them.</li>
                            <li style={{ listStyleType: 'circle' }}>Buy as you go: The client can scroll through and buy the service and package according to the requirements a client/trainer will decide. Based on the service/package you purchase, new content will be shared with you no restriction will be made on app usage.</li>
                            <li style={{ listStyleType: 'circle' }}>Refunds: No refunds will be granted.</li>
                            <li style={{ listStyleType: 'circle' }}>Credits: Credits must be used within one year of cancellation or downgrade and will be forfeited afterward.</li>
                            <li style={{ listStyleType: 'circle' }}>Rejected Payments: An email notice will be sent to specified users on the account. There will be a grace period of 5 days to update the payment method and complete payment. After the grace period, services provided under the purchase will be paused until payment.</li>
                            <li style={{ listStyleType: 'circle' }}>Trial Plans: You acknowledge and agree that we may, from time to time in our sole discretion, offer trial usage plans and that (i) you shall comply with any additional terms, restrictions and/or limitations imposed by us on any such plan; and (ii) we may at any time and for any reason, without liability to you or any other person alter, amend, modify or cancel any aspect of such plan, including without limitation, the term, access rights, fees, nature, scope, features, functionality, operation and content associated therewith.</li>
                            <li style={{ listStyleType: 'circle' }}>Licensed Workspace: Your access to your Workspace and our proprietary services is through a license, which is limited, revocable, personal, non-transferable, non-assignable, non-sublicensable and non-exclusive. Such license is defined by all of the conditions of these Terms of Service.</li>
                            <li style={{ listStyleType: 'circle' }}>Other: You will invite your client(s) to connect with you on the Service, and your client(s) will then download the client mobile app to use the Service. You hereby acknowledge and agree that you are responsible for identifying and bringing such client(s) onto the Service and accept and assume all responsibility and liability for all interactions with your client(s). In particular, but without limitation, you are responsible for making all payment arrangements with your client(s).</li>
                          </ul>
                        </li>
                    </ul>
                    <br />
                    <p><b>Clients.</b> As noted above, the service that we offer to clients (the “Client Service”) is mobile app-based, and there is no charge for clients to download and use the mobile app. Any fee you are charged is just a tiny service charge to make everything in the app active and safe, and we disclaim all responsibility concerning your interaction with your trainer (and you hereby acknowledge and agree with such disclaimer). Your access to the Client Service is through a license, which is limited, revocable, personal, non-transferable, non-assignable, non-sublicensable and non-exclusive, and subject to all of the conditions of these Terms of Service.</p>
                    <p>Payment Processing. Payment processing services for our services to our Trainers are provided by Stripe and their respective affiliates or other payment processing services providers as we may choose from time to time (collectively referred to herein as the “Payment Processing Service Provider” ). You hereby authorize us to share with the Payment Processing Services Provider the financial information, banking details, transaction information and account information provided by you and/or related to your use of our services as is reasonably required for the provision of the payment processing features contemplated by our services. You also agree that we are not responsible or liable for using or misusing such financial information, banking details, transaction information and account information by the Payment Processing Services Provider or any third party. Regarding our use of Stripe in particular, please go to [<a style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} href='https://stripe.com/legal'>https://stripe.com/legal</a>] for additional terms and conditions for your transaction. By using Stripe, you additionally agree that you have read and shall be bound by all the terms and conditions set forth. Information you provide us under this Payment Processing section is subject to the terms of our <Link style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} to="/privacy-policy">Privacy Policy</Link>.</p>
                    <p style={{ fontWeight: 'bold' }}>Payment of Fees to KoCH by Trainers</p>
                    <p>You acknowledge and agree that:</p>
                    <ul style={{ paddingLeft: '2%' }}>
                        <li className='liShape'>UNTIL THE SERVICE IS PROPERLY TERMINATED BY YOU OR US FOLLOWING YOUR AGREEMENT WITH US, YOU SHALL TIMELY AGREE TO ALL THE TERMS WE HAVE MADE WITH YOU AND EXPENSES TO RUN THE PLATFORM WILL BE DEDUCTED AS REQUIRED AND EACH TRAINER WILL BE PROVIDED WITH THE  CLEAR BIFERGATION OF ALL OF THOSE CHARGES WHICH YOU HAVE AGREED TO PAY (“FEES”).</li>
                        <li className='liShape'>THE FEES SHALL BE DEDUCTED FROM THE PAYMENT THAT THE PLATFORM WILL PASS YOU FOR WHICH CLIENTS HAVE BOUGHT THE PRODUCT THAT THE TRAINER HAS LISTED ON THE PLATFORM.</li>
                        <li className='liShape'>TO MAKE THE PERSON ELIGIBLE AS A TRAINER AND ELIGIBLE INDIVIDUAL TO ACCEPT PAYMENT ACCORDING TO THE GUIDELINES OF PAYMENT PROCESSING SERVICE, IF THE PROVIDER KNOW YOUR CUSTOMER (“KYC”) NEEDS TO BE COMPLETED, IT WILL BE DONE BY  ASKING SOME REQUIRED PERSONAL QUESTIONS AND OTHER REQUIRED DETAILS. TO GET PAID, THE NUMBER OF CHARGES THE CUSTOMER PAID WILL CUMMULATE FOR A WEEK AND AT THE END OF EACH WEEK, THESE CHARGES WILL BE AUTOMATICALLY DEPOSITED TO THE TRAINERS BANK ACCOUNT THAT WAS PROVIDED.</li>
                        <li className='liShape'>THE DAY FOR EACH WEEK`S SETTLEMENT WILL BE PROGRAMMABLE BY EITHER KOCH OR THE TRAINER ITSELF. THE SETTLEMENT PERIOD CAN BE CHANGED FROM WEEK TO MULTIPLE WEEKS, MONTHS, ANNUALLY, OR MANUALLY IT IS BASED ON THE CHOICE OF THE INDIVIDUAL`S PREFERENCE. THE DEFAULT OPTION FOR THE SETTLEMENT PERIOD IS SET AS MONDAY OF EACH WEEK. INSTANT CHECKOUT OF THE COLLECTED SETTLEMENT  CHARGES OPTION WILL BE ALSO GIVEN TO THE TRAINER, BUT THE FEES THAT NEED TO BE PAID TO DO THIS PROCESS WILL BE CHARGED FROM THE TOTAL SETTLEMENT AMOUNT THAT TRAINER WILL TRANSFER. PLEASE CHECK ALL THE CHARGES THAT CAN BE <a style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} href='https://stripe.com/en-ca/connect/pricing'>CHARGED IF YOU FALL UNDER THESE CIRCUMSTANCES</a>.</li>
                        <li className='liShape'>You shall be responsible for any currency conversion charges as well as sales, service, value-added, use, excise, consumption and any other taxes, duties and charges of any kind, if any, imposed by any federal, provincial or local governmental entity on any Fees other than any taxes imposed on, or concerning our income.</li>
                        <li className='liShape'>NOTWITHSTANDING ANY OTHER TERM OF THE TERMS OF SERVICE, WE SHALL BE ENTITLED TO AMEND THE FEE SCHEDULE FROM TIME TO TIME BY GIVING YOU THIRTY (30) DAYS WRITTEN NOTICE OF SUCH AMENDMENT, WHICH NOTICE SHALL AMEND THE FEE SCHEDULE ACCORDINGLY. WE SHALL BE BINDING ON YOU, AS OF YOUR NEXT MONTHLY OR ANNUAL BILLING CYCLE, AS APPLICABLE (the “Amended Fee Schedule”).</li>
                        <li className='liShape'>SUPPOSE YOU AGREE TO THE TERMS OF SERVICE AS AMENDED FROM TIME TO TIME BUT DO NOT AGREE TO A SPECIFIC AMENDED FEE SCHEDULE. IN THAT CASE, YOU AND ALL TRAINERS AND CLIENTS WHO HAVE BEEN AUTHORISED TO ACCESS AND USE THE SERVICES PROCURED BY YOU CANNOT USE THE SERVICES AFTER THE END OF YOUR CURRENT MONTHLY OR ANNUAL BILLING CYCLE, AS APPLICABLE. THE TERMS OF SERVICE MUST BE TERMINATED BY YOU FOLLOWING THESE TERMS OF SERVICE ON OR BEFORE THE LAST DAY OF YOUR CURRENT MONTHLY OR ANNUAL BILLING CYCLE, AS APPLICABLE.</li>
                        <li className='liShape'>You acknowledge and agree that we may, from time to time in our sole discretion, offer Trial Usage Plans and that (i) you shall comply with all additional terms, restrictions and/or limitations imposed by us on any such Trial Usage Plan; and (ii) we may at any time and for any reason, without liability to you or any other person alter, amend, modify or cancel any aspect of such Trial Usage Plans, including without limitation, the term, access rights, Fees, nature, scope, features, functionality, operation and content associated therewith.</li>
                    </ul>
                </div>

                <div style={{ fontSize: '13.5px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>Intellectual Property Rights</h2>
                    <p>The Website and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by the Company, its licensors, or other providers of such material. They are protected by Canadian and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>
                    <p>User, trainer and partner-supplied content and associated IPR are owned by them. KoCH does not own or have IPR rights/liability to any such user, trainer or partner-supplied content.</p>
                    <p>These Terms of Service permit you to use the Website for personal, non-commercial use only. Your access to the services provided on the Website (and, if applicable, the mobile application) is subject to your compliance with all of the terms and conditions set forth herein and incorporated herein by reference.  You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Website except as follows:</p>
                    <ul style={{ paddingLeft: '2%' }}>
                        <li className='liShape'>Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.</li>
                        <li className='liShape'>You may store files automatically cached by your Web browser for display enhancement purposes.</li>
                        <li className='liShape'>You may print or download one copy of a reasonable number of pages of the Website for your own personal, non-commercial use and not for further reproduction, publication, or distribution.</li>
                        <li className='liShape'>If we provide desktop, mobile, or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications.</li>
                        <li className='liShape'>If we provide social media features with certain content, you may take such actions as are enabled by such features.</li>
                    </ul>
                    <br />
                    <p>You must not:</p>
                    <ul style={{ paddingLeft: '2%' }}>
                        <li className='liShape'>Modify copies of any materials from this site.</li>
                        <li className='liShape'>Use any illustrations, photographs, video or audio sequences, or graphics separately from the accompanying text.</li>
                        <li className='liShape'>Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this site.</li>
                    </ul>
                    <br />
                    <p>You must not access or use for any commercial purposes any part of the Website or any services or materials available through the Website.</p>
                    <p>If you wish to use material on the Website other than that set out in this section, please address your request to <a style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} href='mailto:support@mykoch.app'>support@mykoch.app</a>.</p>
                    <p>If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Website in breach of the Terms of Service, your right to use the Website will stop immediately. You must, at our option, return or destroy any copies of the materials you have made. No right, title or interest in or to the Website or any content on the Website is transferred to you, and all rights not expressly granted are reserved by the Company. Any use of the Website not expressly permitted by these Terms of Service is a breach of these Terms of Service and may violate copyright, trademark, and other laws.</p>
                </div>

                <div style={{ fontSize: '13.5px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>Trademarks</h2>
                    <p>The Company name, the Company logo, and all related names, logos, product and service names, designs, and slogans are trademarks of the Company or its affiliates or licensors. You must not use such marks without the Company's prior written permission. All other names, logos, product and service names, designs, and slogans on this Website are the trademarks of their respective owners.</p>
                </div>

                <div style={{ fontSize: '13.5px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>Prohibited Uses</h2>
                    <p>You may use the Website only for lawful purposes and following these Terms of Service. You agree not to use the Website:</p>
                    <ul style={{ paddingLeft: '2%' }}>
                        <li className='liShape'>In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from Canada or other countries).</li>
                        <li className='liShape'>In any way that violates our <Link style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} to="/privacy-policy">Privacy Policy</Link>.</li>
                        <li className='liShape'>To exploit, harm, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise.</li>
                        <li className='liShape'>To send, knowingly receive, upload, download, use, or re-use any material that does not comply with the <a style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} href='https://www.mykoch.app'>Content Standards</a> in these Terms of Service.</li>
                        <li className='liShape'>To transmit or procure the sending of any advertising or promotional material without our prior written consent, including any “junk mail,” “chain letter,” “spam,” or any other similar solicitation.</li>
                        <li className='liShape'>To impersonate or attempt to impersonate the Company, a Company employee, another user, or any other person or entity (including, without limitation, by using email addresses or screen names associated with any of the foregoing).</li>
                        <li className='liShape'>To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Website, or which, as determined by us, may harm the Company or users of the Website or expose them to liability.</li>
                    </ul>
                    <br />
                    <p>Additionally, you agree not to:</p>
                    <ul style={{ paddingLeft: '2%' }}>
                        <li className='liShape'>Use the Website in any manner that could disable, overburden, damage, or impair the Site or interfere with any other party's use of the Website, including their ability to engage in real-time activities through the Website.</li>
                        <li className='liShape'>Use any robot, spider, or other automatic device, process, or means to access the Website for any purpose, including monitoring or copying any of the material on the Website.</li>
                        <li className='liShape'>Use any manual process to monitor or copy any of the material on the Website or for any other purpose not expressly authorized in these Terms of Service without our prior written consent.</li>
                        <li className='liShape'>Use any device, software, or routine that interferes with the proper working of the Website.</li>
                        <li className='liShape'>Introduce any viruses, Trojan horses, worms, logic bombs, or other malicious or technologically harmful material.</li>
                        <li className='liShape'>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Website, the server on which the Website is stored, or any server, computer, or database connected to the Website.</li>
                        <li className='liShape'>Attack the Website via a denial-of-service attack or a distributed denial-of-service attack.</li>
                        <li className='liShape'>Otherwise attempt to interfere with the proper working of the Website.</li>
                    </ul>
                    <br />
                </div>

                <div style={{ fontSize: '13.5px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>User Contributions</h2>
                    <p>The Website contains message boards, chat rooms, forums, bulletin boards, videos, and a variety of other interactive features (collectively, “<b>Interactive Services</b>“) that allow users to post, submit, publish, display, or transmit to other users or other persons (hereinafter, “<b>post</b>“) content or materials (collectively, “<b>User Contributions</b>“) on or through the Website.</p>
                    <p>All User Contributions must comply with the Content Standards set out in these Terms of Service.</p>
                    <p>Other than personally identifiable information which is protected by our <Link style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} to="/privacy-policy">Privacy Policy</Link> or intellectual property owned by trainers, any User Contribution you post to the site will be considered non-confidential and non-proprietary. By providing any User Contribution on the Website, you grant us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns the right to use, reproduce, modify, perform, display, distribute, and otherwise disclose to third parties any such material for any purpose.</p>
                    <p>You represent and warrant that</p>
                    <ul style={{ paddingLeft: '2%' }}>
                        <li className='liShape'>You own or control all rights in and to the User Contributions and have the right to grant the license granted above to us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns.</li>
                        <li className='liShape'>All of your User Contributions do and will comply with these Terms of Service.</li>
                    </ul>
                    <br />
                    <p>You understand and acknowledge that you are responsible for any User Contributions you submit or contribute, and you, not the Company, have full responsibility for such content, including its legality, reliability, accuracy, and appropriateness.</p>
                    <p>We are not responsible or liable to any third party for the content or accuracy of any User Contributions posted by you or any other user of the Website.</p>
                    <p>Notwithstanding anything to the contrary, you grant us permission to use your otherwise personally identifiable information in de-identified form.</p>
                </div>

                <div style={{ fontSize: '13.5px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>Monitoring and Enforcement; Termination</h2>
                    <p>We have the right to:</p>
                    <ul style={{ paddingLeft: '2%' }}>
                        <li className='liShape'>Remove or refuse to post any User Contributions for any or no reason at our sole discretion.</li>
                        <li className='liShape'>Take any action for any User Contribution that we deem necessary or appropriate in our sole discretion, including if we believe that such User Contribution violates the Terms of Service, including the Content Standards, infringes any intellectual property right or other right of any person or entity, threatens the personal safety of users of the Website or the public, or could create liability for the Company.</li>
                        <li className='liShape'>Disclose your identity or other information about you to any third party who claims that the material you post violates their rights, including their intellectual property rights or their right to privacy.</li>
                        <li className='liShape'>Take appropriate legal action, including, without limitation, referral to law enforcement for any illegal or unauthorized use of the Website.</li>
                        <li className='liShape'>Terminate or suspend your access to all or part of the Website for any or no reason, including, without limitation, any violation of these Terms of Service.</li>
                    </ul>
                    <br />
                    <p>Without limiting the foregoing, we have the right to cooperate fully with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Website. YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.</p>
                    <p>However, we cannot review material before it is posted on the Website, and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications, or content provided by any user or third party. We have no liability or responsibility to anyone for the performance or nonperformance of the activities described in this section.</p>
                </div>

                <div style={{ fontSize: '13.5px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>Content Standards</h2>
                    <p>These content standards apply to any and all User Contributions and use of Interactive Services. User Contributions must, in their entirety, comply with all applicable federal, state, local, and international laws and regulations. Without limiting the foregoing, User Contributions must not:</p>
                    <ul style={{ paddingLeft: '2%' }}>
                        <li className='liShape'>Contain any material that is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory, or otherwise objectionable.</li>
                        <li className='liShape'>Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age.</li>
                        <li className='liShape'>Infringe any patent, trademark, trade secret, copyright, or other intellectual property or other rights of any other person.</li>
                        <li className='liShape'>Violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms of Service and our <Link style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} to="/privacy-policy">Privacy Policy</Link>.</li>
                        <li className='liShape'>Be likely to deceive any person.</li>
                        <li className='liShape'>Promote any illegal activity, or advocate, promote, or assist any unlawful act.</li>
                        <li className='liShape'>Cause annoyance, inconvenience, or needless anxiety or be likely to upset, embarrass, alarm, or annoy any other person.</li>
                        <li className='liShape'>Impersonate any person or misrepresent your identity or affiliation with any person or organization.</li>
                        <li className='liShape'>Give the impression that they emanate from or are endorsed by us or any other person or entity if this is not true.</li>
                    </ul>
                    <br />
                </div>

                <div style={{ fontSize: '13.5px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>Copyright Infringement</h2>
                    <p>If you believe that any User Contributions violate your copyright, please see our <Link style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} to="/copyright">Copyright Policy</Link> for instructions on sending us a notice of copyright infringement. It is the policy of the Company to terminate the user accounts of repeat infringers.</p>
                </div>

                <div style={{ fontSize: '13.5px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>Reliance on Information Posted</h2>
                    <p>The information presented on or through the Website is made available solely for general information purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Website, or by anyone who may be informed of any of its contents.</p>
                    <p>This Website may include content provided by third parties, including materials provided by other users, bloggers, and third-party licensors, syndicators, aggregators, and/or reporting services. All statements and/or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by the Company are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect the opinion of the Company. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.</p>
                </div>

                <div style={{ fontSize: '13.5px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>Changes to the Website</h2>
                    <p>We may update the content on this Website from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the Website may be out of date at any given time, and we are under no obligation to update such material.</p>
                </div>

                <div style={{ fontSize: '13.5px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>Information About You and Your Visits to the Website</h2>
                    <p>All information we collect on this Website is subject to our <Link style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} to="/privacy-policy">Privacy Policy</Link>. By using the Website, you consent to all actions we take with respect to your information under our <Link style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} to="/privacy-policy">Privacy Policy</Link>.</p>
                </div>

                <div style={{ fontSize: '13.5px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>Online Purchases and Other Terms and Conditions</h2>
                    <p>All purchases through our site or other transactions for the sale of services through the Website, or resulting from visits made by you, are governed by these Terms of Service.</p>
                    <p>Additional terms and conditions may also apply to specific portions, services, or features of the Website. All such additional terms and conditions are hereby incorporated by this reference into these Terms of Service.</p>
                </div>

                <div style={{ fontSize: '13.5px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>Linking to the Website and Social Media Features</h2>
                    <p>You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval, or endorsement on our part.</p>
                    <p>This Website may provide certain social media features that enable you to:</p>
                    <ul style={{ paddingLeft: '2%' }}>
                        <li className='liShape'>Link from your own or certain third-party websites to certain content on this Website.</li>
                        <li className='liShape'>Send emails or other communications with certain content, or links to certain content, on this Website.</li>
                        <li className='liShape'>Cause limited portions of content on this Website to be displayed or appear to be displayed on your own or certain third-party websites.</li>
                    </ul>
                    <br />
                    <p>You may use these features solely as they are provided by us, solely with respect to the content they are displayed with, and otherwise in accordance with any additional terms and conditions we provide with respect to such features. Subject to the foregoing, you must not:</p>
                    <ul style={{ paddingLeft: '2%' }}>
                        <li className='liShape'>Establish a link from any website that is not owned by you.</li>
                        <li className='liShape'>Cause the Website or portions of it to be displayed on, or appear to be displayed by, any other site, for example, framing, deep linking, or in-line linking.</li>
                        <li className='liShape'>Link to any part of the Website other than the homepage.</li>
                        <li className='liShape'>Otherwise take any action with respect to the materials on this Website that is inconsistent with any other provision of these Terms of Service.</li>
                    </ul>
                    <br />
                    <p>The website from which you are linking, or on which you make certain content accessible, must comply in all respects with the Content Standards set out in these Terms of Service.</p>
                    <p>You agree to cooperate with us in causing any unauthorized framing or linking immediately to stop. We reserve the right to withdraw linking permission without notice.</p>
                    <p>We may disable all or any social media features and any links at any time without notice at our discretion.</p>
                </div>

                <div style={{ fontSize: '13.5px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>Links from the Website</h2>
                    <p>If the Website contains links to other sites and resources provided by third parties, these links are provided for your convenience only. This includes links contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those sites or resources and accept no responsibility for them or for any loss or damage arising from your use of them. If you decide to access any of the third-party websites linked to this Website, you do so entirely at your own risk and subject to the terms and conditions of use for such websites.</p>
                </div>

                <div style={{ fontSize: '13.5px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>Geographic Restrictions</h2>
                    <p>The owner of the Website is based in Ontario, Canada. We make no claims that the Website or any of its content is accessible or appropriate outside of Canada. Access to the Website may not be legal by certain persons or in certain countries. If you access the Website from outside Canada, you do so on your own initiative and are responsible for compliance with local laws, including but not limited to laws related to data protection and personal information.</p>
                </div>

                <div style={{ fontSize: '13.5px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>Disclaimer of Warranties</h2>
                    <p>You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Website will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.</p>
                    <p>YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION TO THE COMPLETENESS, TIMELINESS, USEFULNESS, COMPLETENESS, ADEQUACY, SUITABILITY, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE OR ANY SERVICES OBTAINED THROUGH THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</p>
                    <p>TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.</p>
                    <p>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CAN BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
                </div>

                <div style={{ fontSize: '13.5px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>Limitation on Liability</h2>
                    <p>TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT, WILL THE COMPANY, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.</p>
                    <p>TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COLLECTIVE LIABILITY OF THE COMPANY AND ITS SUBSIDIARIES AND AFFILIATES, AND THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, AND DIRECTORS, TO ANY PARTY (REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT, OR OTHERWISE) EXCEED THE AMOUNT YOU HAVE PAID TO THE COMPANY IN THE LAST SIX MONTHS FOR THE APPLICABLE SERVICE OUT OF WHICH LIABILITY AROSE OR $50, WHICHEVER IS GREATER.</p>
                    <p>THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
                </div>

                <div style={{ fontSize: '13.5px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>Business-Specific Disclaimers</h2>
                    <ul style={{ paddingLeft: '2%' }}>
                        <li className='liShape'>KoCH does not engage in the practice of medicine or dispense medicine. The contents contained in the Website are provided solely for informational purposes.
                            <ul style={{ paddingLeft: '2%' }}>
                              <li style={{ listStyleType: 'circle' }}>No information obtained from this Website is intended to be instructional for medical diagnosis or treatment. The information should not be considered complete, nor should it be relied upon to suggest a course of treatment for a particular individual or for any purpose whatsoever. The information obtained from this Website should not be used in place of a visit, call, consultation or the advice of your physician or other qualified healthcare provider. Information obtained from the Website is not exhaustive and does not cover diseases, ailments, physical conditions or their treatment. If you have any healthcare-related questions, including without limitation with respect to pre-existing conditions or injuries you may sustain during your training, we strongly encourage you to call or see your physician or other qualified healthcare provider promptly. We also strongly recommend that you consult with your physician or other qualified healthcare provider before embarking on a new treatment, diet or fitness program. You should never disregard medical advice or delay in seeking it, including because of something you have read on this Website.</li>
                              <li style={{ listStyleType: 'circle' }}>KoCH does not directly or indirectly practice medicine or dispense medical services,  and is not (nor is it intended to be) a substitute for the services of a physician, a health provider, or any trained health professional. We do not provide, nor do we allow anyone connected with us to provide, medical advice of any kind. These concerns should be addressed and discussed with your physician, health care provider, family nutritionist or registered dietitian.</li>
                              <li style={{ listStyleType: 'circle' }}>You expressly acknowledge and agree that KoCH is not and has not engaged in the practice of medicine and that all decisions made have been and will be the exclusive responsibility of you and your physician. We expressly disclaim any responsibility or liability for any adverse consequences or damages whatsoever resulting from your reliance on this Website or your trainer, or any information you may receive from the mobile app or any links, social media or otherwise.</li>
                            </ul>
                        </li>
                        <li className='liShape'>Disclaimer of Third Party Information
                            <ul style={{ paddingLeft: '2%' }}>
                              <li style={{ listStyleType: 'circle' }}>Statements made in websites, newsgroups, message boards, email, forums, conferences and chats reflect the views of their authors and not KoCH. Forum managers, forum hosts, content providers, Information Providers or merchants appearing on the Website are not authorized KoCH spokespersons, and their views do not necessarily reflect those of KoCH. KoCH disclaims all responsibility and liability whatsoever in connection therewith.</li>
                            </ul>
                        </li>
                        <li className='liShape'>Licenses and Idea Submissions</li>
                    </ul>
                    <br />
                    <p>You agree to grant KoCH a non-exclusive, worldwide, royalty-free, perpetual license, with the right to sublicense, reproduce, distribute, transmit, create derivative works of, publicly display and publicly perform any materials and other information (including, without limitation, ideas contained therein for new or improved products or services) you submit to public areas of KoCH (such as BBSs, forums and chat rooms) by all means and in any media now known or hereafter developed. You hereby waive your moral rights in any such materials and information, and you hereby warrant that any such materials and information are original with you, or that you have the right to submit such materials and information. You agree that you shall have no recourse against KoCH for any alleged or actual infringement or misappropriation of any proprietary right in your communication to us.</p>
                </div>

                <div style={{ fontSize: '13.5px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>Indemnification</h2>
                    <p>You agree to defend, indemnify, and hold harmless the Company, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms of Service or your use of the Website, including, but not limited to, your User Contributions, any use of the Website’s content, services, and products other than as expressly authorized in these Terms of Service, or your use of any information obtained from the Website.</p>
                </div>

                <div style={{ fontSize: '13.5px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>Governing Law and Jurisdiction</h2>
                    <p>All matters relating to the Website and these Terms of Service, and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the Province of Ontario without giving effect to any choice or conflict of law provision or rule (whether of the Ontario or any other jurisdiction).</p>
                    <p>Any legal suit, action, or proceeding arising out of, or related to, these Terms of Service or the Website shall be instituted exclusively in a federal or state court located in the Province of Ontario, although we retain the right to bring any suit, action, or proceeding against you for breach of these Terms of Service in your country of residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</p>
                </div>

                <div style={{ fontSize: '13.5px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>Limitation on Time to File Claims</h2>
                    <p>ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF SERVICE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.</p>
                </div>

                <div style={{ fontSize: '13.5px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>Waiver and Severability</h2>
                    <p>No waiver by the Company of any term or condition set out in these Terms of Service shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms of Service shall not constitute a waiver of such right or provision.</p>
                    <p>If any provision of these Terms of Service is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Service will continue in full force and effect.</p>
                </div>

                <div style={{ fontSize: '13.5px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>Entire Agreement</h2>
                    <p>The Terms of Service, our Privacy Policy, our Copyright Policy, and any other Company written policies constitute the sole and entire agreement between you and the Company regarding the Website and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the Website. </p>
                </div>

                <div style={{ fontSize: '13.5px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>Your Comments and Concerns</h2>
                    <p>This Website is operated by KoCH, located at 42 Scarsdale Rd #Unit - 2 Toronto, ON, Canada M3B 2R7.</p>
                    <p>All notices of copyright infringement claims should be sent to the copyright agent designated in our <Link style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} to="/copyright">Copyright Policy</Link> in the manner and by the means set out therein.</p>
                    <p>All other feedback, comments, requests for technical support, and other communications relating to the Website should be directed to: <a style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} href='mailto:support@mykoch.app'>support@mykoch.app</a>.</p>
                </div>

            </section>
        </>
  )
}

export default TermsofServiceComponent;