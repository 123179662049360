import React from 'react';
import { Button, Divider, Grid, GridItem, HStack, Image, VStack } from '@chakra-ui/react';
import kochclientmobile from '../assets/kochclientmobile.jpg';
import appstore from '../assets/appstore.jpg';
import playstore from '../assets/playstore.jpg';
import simpleyourfitness from '../assets/simpleyourfitness.jpg';
import iphone13 from '../assets/iphone13.jpg';
import startyourjourney from '../assets/startyourjourney.jpg';
import rotatedpic from '../assets/rotatedpic.jpg';

function KochclientComponent() {
    return (
        <>
            <div style={{ padding: '8vw', display: 'flex', flexDirection: 'row', flexWrap: 'wrap-reverse', alignItems: 'center', justifyContent: 'space-around' }}>
                <VStack align="start" w={['90%', '70%', '55%']} spacing={6}>
                    <h1 style={{ fontSize: '4em', fontWeight: 700, textTransform: 'uppercase' }}>This time, fitness is fitness. No more extra burdens. Track your <span style={{ textDecoration: 'underline #EDFF00' }}>schedule,</span> Start training <span style={{ textDecoration: 'underline #EDFF00' }}>anywhere.</span></h1>
                    <p style={{ fontSize: '2em' }}>Start your journey with your personal coach now !</p>
                    <a href='https://testflight.apple.com/join/T82P7Sgw' target="_blank" >
                        <Button color="white" fontSize="20px" size="md" p={"1.2em"} pl={["2em", "3em", "3.84em"]} pr={["2em", "3em", "3.84em"]} bgColor="black" colorScheme="blackAlpha">DOWNLOAD NOW</Button>
                    </a>
                    <HStack>
                        <a href='https://testflight.apple.com/join/T82P7Sgw' target="_blank" >
                            <Image cursor="pointer" src={appstore} alt="appstore" pr={[0, 0, 10]} w={['50%', '80%', '100%']} />
                        </a>
                        {/* <Image src={playstore} alt="playstore" w={['50%', '80%', '100%']} /> */}
                    </HStack>
                </VStack>
                <Image src={kochclientmobile} alt="kochclientmobile" />
            </div>

            <p style={{ width: '100vw', height: '64px', backgroundImage: `url(${simpleyourfitness})`, backgroundRepeat: 'repeat' }}></p>

            <div style={{ padding: '10vw', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                <h1 style={{ fontSize: '5em', fontWeight: 700, textTransform: 'uppercase', paddingBottom: '10vh' }}>The way to simple your <span style={{ color: '#EDEA00' }}>fitness life</span></h1>
                <div style={{ display: 'flex', flexFlow: 'row wrap-reverse', justifyContent: 'space-around', width: '100%', alignItems: 'center' }}>
                    <Grid templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)']} gap={6}>
                        <GridItem w={['70vw', '45vw', '22vw']} color="white" bg='black' p={16}>
                            <p style={{ fontSize: '2em', fontWeight: 700, paddingBottom: '3vh', background: 'linear-gradient(92.54deg, #EDFFa1 6.14%, #EDFF00 96.11%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>Direct Message</p>
                            <p style={{ fontSize: '1.5em' }}>Keep in touch with your coach at all times</p>
                        </GridItem>
                        <GridItem w={['70vw', '45vw', '22vw']} color="white" bg='black' p={16}>
                            <p style={{ fontSize: '2em', fontWeight: 700, paddingBottom: '3vh', background: 'linear-gradient(92.54deg, #EDFFa1 6.14%, #EDFF00 96.11%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>Professional work Out video</p>
                            <p style={{ fontSize: '1.5em' }}>Recorded by a professional senior trainer to ensure your workout moves are correct</p>
                        </GridItem>
                        <GridItem w={['70vw', '45vw', '22vw']} color="white" bg='black' p={16}>
                            <p style={{ fontSize: '2em', fontWeight: 700, paddingBottom: '3vh', background: 'linear-gradient(92.54deg, #EDFFa1 6.14%, #EDFF00 96.11%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>Habit Tracker</p>
                            <p style={{ fontSize: '1.5em' }}>Record your habit and let the coach make a more flexible plan for you</p>
                        </GridItem>
                        <GridItem w={['70vw', '45vw', '22vw']} color="white" bg='black' p={16}>
                            <p style={{ fontSize: '2em', fontWeight: 700, paddingBottom: '3vh', background: 'linear-gradient(92.54deg, #EDFFa1 6.14%, #EDFF00 96.11%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>Market</p>
                            <p style={{ fontSize: '1.5em' }}>Browse the services posted by your personal trainer and choose the one that suits you best</p>
                        </GridItem>
                        <GridItem w={['70vw', '45vw', '22vw']} color="white" bg='black' p={16}>
                            <p style={{ fontSize: '2em', fontWeight: 700, paddingBottom: '3vh', background: 'linear-gradient(92.54deg, #EDFFa1 6.14%, #EDFF00 96.11%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>Calendar</p>
                            <p style={{ fontSize: '1.5em' }}>Check the available time of your personal trainer and book appointments at any time</p>
                        </GridItem>
                        <GridItem w={['70vw', '45vw', '22vw']} color="white" bg='white' p={16}>
                            <p style={{ fontSize: '2em', fontWeight: 700, background: 'linear-gradient(92.54deg, #EDEAa1 6.14%, #EDEA00 96.11%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>And more waiting you to explore!</p>
                        </GridItem>
                    </Grid>
                    <Image src={iphone13} alt="iphone13" />
                </div>
            </div>

            <div style={{ backgroundColor: 'black' }}>
                <p style={{ width: '100vw', height: '78px', backgroundImage: `url(${startyourjourney})`, backgroundRepeat: 'repeat' }}></p>
                <div style={{ padding: '10vw', paddingTop: '5vw', paddingRight: 0, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}>
                    <VStack color="white" align="start" w={['90%', '50%', '40%']}>
                        <h1 style={{ fontSize: '4em', fontWeight: 700, textTransform: 'uppercase' }}>Communicate with your coach Anytime. This time, Everything you need is <span style={{ color: '#EDFF00' }}>here</span>.</h1>
                        <a href='https://testflight.apple.com/join/T82P7Sgw' target="_blank" >
                            <Button style={{ marginTop: '2vh', marginBottom: '2vh' }} textTransform="uppercase" color="black" fontSize="1.5em" size="md" p={"1.2em"} bgColor="#EDFF00">Download App For Free Now</Button>
                        </a>
                    </VStack>
                    <Image src={rotatedpic} alt="rotatedpic" />
                </div>
                <Divider w="80%" margin="auto" border="10px solid" borderColor="#fff" opacity={1} />
            </div>

            {/* <div style={{ padding: '7vw' }}>
                <VStack>
                    <Image src="kochlogo.png" alt="kochlogo" height="130px" />
                    <p style={{ fontSize: '4em', fontWeight: 700, textTransform: 'uppercase', paddingTop: '8vh' }}><span style={{ color: '#FF9505' }}>One</span> for everything</p>
                </VStack>
            </div> */}
        </>
    )
}

export default KochclientComponent;