import React from 'react'
import { Image, Input, Button, Container, HStack, VStack } from '@chakra-ui/react';

function ClientFormComponent() {
    return (
        <div>
            <section className="hero-section" style={{ padding: '20vh 0 10vh 0', alignItems: 'center', backgroundColor: '#eaeaea' }}>
                <h1>Client Form</h1>
                <Container maxW='7xl' fontSize="2xl">
                    <form action="https://docs.google.com/forms/u/3/d/e/1FAIpQLScRS8mISGq1IuvEu6qmj-3OjpyFBoMgLqWNAFhMxFn48_IZEg/formResponse" method='POST'>
                        <VStack align="start" pt={5} pb={5} p={[5, 10, 12]} borderRadius="2xl" backgroundColor="#fff" mb={5} >
                            <label style={{ fontWeight: 'bold' }}>What is your name?<span class="color-superscript" /></label>
                            <Input size="xl" variant="flushed" name="entry.13394790" required placeholder='your name' />
                        </VStack>

                        <VStack align="start" pt={5} pb={5} p={[5, 10, 12]} borderRadius="2xl" backgroundColor="#fff" mb={5} >
                            <label style={{ fontWeight: 'bold' }}>Can you please include your email as well?<span class="color-superscript" /></label>
                            <Input size="xl" variant="flushed" type="email" name="entry.1853389789" required placeholder='your email' />
                        </VStack>
                        {/* <Input hidden name='entry.1665930231' value='Client' /> */}
                        <VStack align="start" pt={5} pb={5} p={[5, 10, 12]} borderRadius="2xl" backgroundColor="#fff" mb={5} >
                            <label style={{ fontWeight: 'bold' }}>How was the overall experience while using the "KōCH Client" app?<span class="color-superscript" /></label>
                            <HStack>
                                <input type="radio" id='1' name="entry.1321497212" value="1" />
                                <label for="1">1</label>
                            </HStack>
                            <HStack>
                                <input type="radio" id='2' name="entry.1321497212" value="2" />
                                <label for="2">2</label>
                            </HStack>
                            <HStack>
                                <input type="radio" id='3' name="entry.1321497212" value="3" />
                                <label for="3">3</label>
                            </HStack>
                            <HStack>
                                <input type="radio" id='4' name="entry.1321497212" value="4" />
                                <label for="4">4</label>
                            </HStack>
                            <HStack>
                                <input type="radio" id='5' name="entry.1321497212" value="5" defaultChecked />
                                <label for="5">5</label>
                            </HStack>
                        </VStack>

                        <VStack align="start" pt={5} pb={5} p={[5, 10, 12]} borderRadius="2xl" backgroundColor="#fff" mb={5} >
                            <label style={{ fontWeight: 'bold' }}>Was there an issue that you encountered while using the app? If yes, try to describe it as specific as you can so our team can solve it quickly.<span class="color-superscript" /></label>
                            <Input size="xl" variant="flushed" name="entry.1395642224" required placeholder='your answer' />
                        </VStack>

                        <VStack align="start" pt={5} pb={5} p={[5, 10, 12]} borderRadius="2xl" backgroundColor="#fff" mb={5} >
                            <label style={{ fontWeight: 'bold' }}>Did you find the buttons, icons, and menus easy to navigate? Were there any parts of the app that were confusing or difficult to use? Please tell us in the "Other" section if there is any.</label>
                            <HStack>
                                <input type="radio" id='yes' name="entry.14034765" value="Yes" />
                                <label for="yes">yes</label>
                            </HStack>
                            <HStack>
                                <input type="radio" id='no' name="entry.14034765" value="No" />
                                <label for="no">no</label>
                            </HStack>
                            <Input size="xl" variant="flushed" name="entry.14034765" placeholder='other' />
                        </VStack>

                        <VStack align="start" pt={5} pb={5} p={[5, 10, 12]} borderRadius="2xl" backgroundColor="#fff" mb={5} >
                            <label style={{ fontWeight: 'bold' }}>Did the KōCH app meet your expectations for gaining online fitness coaching? Were you satisfied with the features and functionality of the app, and did it help you solve any problems you were facing as a fitness coach? Tell us using the "Other" option if you have feedback on features that you'd like to have on KōCH.</label>
                            <HStack>
                                <input type="radio" id='yes_d' name="entry.167808136" value="Yes" />
                                <label for="yes_d">yes</label>
                            </HStack>
                            <HStack>
                                <input type="radio" id='not_at_all' name="entry.167808136" value="not_at_all" />
                                <label for="not_at_all">not at all</label>
                            </HStack>
                            <HStack>
                                <input type="radio" id='not_really' name="entry.167808136" value="not_really" />
                                <label for="not_really">not really</label>
                            </HStack>
                            <Input size="xl" variant="flushed" name="entry.167808136" placeholder='other' />
                        </VStack>

                        <VStack align="start" pt={5} pb={5} p={[5, 10, 12]} borderRadius="2xl" backgroundColor="#fff" mb={5} >
                            <label style={{ fontWeight: 'bold' }}>How easy was it to use the app? Did you find the layout and design of the app to be user-friendly and intuitive?</label>
                            <HStack spacing={10} align="end">
                                <div>Not satisfied</div>
                                <VStack>
                                    <label for="1_e">1</label>
                                    <input type="radio" id='1_e' name="entry.133336321" value="1" />
                                </VStack>
                                <VStack>
                                    <label for="2_e">2</label>
                                    <input type="radio" id='2_e' name="entry.133336321" value="2" />
                                </VStack>
                                <VStack>
                                    <label for="3_e">3</label>
                                    <input type="radio" id='3_e' name="entry.133336321" value="3" />
                                </VStack>
                                <VStack>
                                    <label for="4_e">4</label>
                                    <input type="radio" id='4_e' name="entry.133336321" value="4" />
                                </VStack>
                                <VStack>
                                    <label for="5_e">5</label>
                                    <input type="radio" id='5_e' name="entry.133336321" value="5" />
                                </VStack>
                                <div>Very satisfied</div>
                            </HStack>
                        </VStack>

                        <VStack align="start" pt={5} pb={5} p={[5, 10, 12]} borderRadius="2xl" backgroundColor="#fff" mb={5} >
                            <label style={{ fontWeight: 'bold' }}>How was your sign-up / login and logout experience?</label>
                            <HStack spacing={10} align="end">
                                <div>Not satisfied</div>
                                <VStack>
                                    <label for="1_f">1</label>
                                    <input type="radio" id='1_f' name="entry.543346792" value="1" />
                                </VStack>
                                <VStack>
                                    <label for="2_f">2</label>
                                    <input type="radio" id='2_f' name="entry.543346792" value="2" />
                                </VStack>
                                <VStack>
                                    <label for="3_f">3</label>
                                    <input type="radio" id='3_f' name="entry.543346792" value="3" />
                                </VStack>
                                <VStack>
                                    <label for="4_f">4</label>
                                    <input type="radio" id='4_f' name="entry.543346792" value="4" />
                                </VStack>
                                <VStack>
                                    <label for="5_f">5</label>
                                    <input type="radio" id='5_f' name="entry.543346792" value="5" />
                                </VStack>
                                <div>Very satisfied</div>
                            </HStack>
                        </VStack>

                        <VStack align="start" pt={5} pb={5} p={[5, 10, 12]} borderRadius="2xl" backgroundColor="#fff" mb={5} >
                            <label style={{ fontWeight: 'bold' }}>How was the Program experience? This includes viewing the workout program as well as navigating the program that was built for you.</label>
                            <HStack spacing={10} align="end">
                                <div>Not satisfied</div>
                                <VStack>
                                    <label for="1_g">1</label>
                                    <input type="radio" id='1_g' name="entry.1753641668" value="1" />
                                </VStack>
                                <VStack>
                                    <label for="2_g">2</label>
                                    <input type="radio" id='2_g' name="entry.1753641668" value="2" />
                                </VStack>
                                <VStack>
                                    <label for="3_g">3</label>
                                    <input type="radio" id='3_g' name="entry.1753641668" value="3" />
                                </VStack>
                                <VStack>
                                    <label for="4_g">4</label>
                                    <input type="radio" id='4_g' name="entry.1753641668" value="4" />
                                </VStack>
                                <VStack>
                                    <label for="5_g">5</label>
                                    <input type="radio" id='5_g' name="entry.1753641668" value="5" />
                                </VStack>
                                <div>Very satisfied</div>
                            </HStack>
                        </VStack>

                        <VStack align="start" pt={5} pb={5} p={[5, 10, 12]} borderRadius="2xl" backgroundColor="#fff" mb={5} >
                            <label style={{ fontWeight: 'bold' }}>How was the experience when navigating the "KōCH" module? This includes purchasing a new package/service as well as using your purchased product(s).</label>
                            <HStack spacing={10} align="end">
                                <div>Not satisfied</div>
                                <VStack>
                                    <label for="1_h">1</label>
                                    <input type="radio" id='1_h' name="entry.1577246236" value="1" />
                                </VStack>
                                <VStack>
                                    <label for="2_h">2</label>
                                    <input type="radio" id='2_h' name="entry.1577246236" value="2" />
                                </VStack>
                                <VStack>
                                    <label for="3_h">3</label>
                                    <input type="radio" id='3_h' name="entry.1577246236" value="3" />
                                </VStack>
                                <VStack>
                                    <label for="4_h">4</label>
                                    <input type="radio" id='4_h' name="entry.1577246236" value="4" />
                                </VStack>
                                <VStack>
                                    <label for="5_h">5</label>
                                    <input type="radio" id='5_h' name="entry.1577246236" value="5" />
                                </VStack>
                                <div>Very satisfied</div>
                            </HStack>
                        </VStack>

                        <VStack align="start" pt={5} pb={5} p={[5, 10, 12]} borderRadius="2xl" backgroundColor="#fff" mb={5} >
                            <label style={{ fontWeight: 'bold' }}>How was the experience with the messaging feature? This includes sending / receiving a message to/from your coach.</label>
                            <HStack spacing={10} align="end">
                                <div>Not satisfied</div>
                                <VStack>
                                    <label for="1_i">1</label>
                                    <input type="radio" id='1_i' name="entry.724201512" value="1" />
                                </VStack>
                                <VStack>
                                    <label for="2_i">2</label>
                                    <input type="radio" id='2_i' name="entry.724201512" value="2" />
                                </VStack>
                                <VStack>
                                    <label for="3_i">3</label>
                                    <input type="radio" id='3_i' name="entry.724201512" value="3" />
                                </VStack>
                                <VStack>
                                    <label for="4_i">4</label>
                                    <input type="radio" id='4_i' name="entry.724201512" value="4" />
                                </VStack>
                                <VStack>
                                    <label for="5_i">5</label>
                                    <input type="radio" id='5_i' name="entry.724201512" value="5" />
                                </VStack>
                                <div>Very satisfied</div>
                            </HStack>
                        </VStack>

                        <VStack align="start" pt={5} pb={5} p={[5, 10, 12]} borderRadius="2xl" backgroundColor="#fff" mb={5} >
                            <label style={{ fontWeight: 'bold' }}>How was the experience in scheduling and creating appointments? This includes requesting appointments for your coach as well as viewing booked appointments.</label>
                            <HStack spacing={10} align="end">
                                <div>Not satisfied</div>
                                <VStack>
                                    <label for="1_j">1</label>
                                    <input type="radio" id='1_j' name="entry.79522774" value="1" />
                                </VStack>
                                <VStack>
                                    <label for="2_j">2</label>
                                    <input type="radio" id='2_j' name="entry.79522774" value="2" />
                                </VStack>
                                <VStack>
                                    <label for="3_j">3</label>
                                    <input type="radio" id='3_j' name="entry.79522774" value="3" />
                                </VStack>
                                <VStack>
                                    <label for="4_j">4</label>
                                    <input type="radio" id='4_j' name="entry.79522774" value="4" />
                                </VStack>
                                <VStack>
                                    <label for="5_j">5</label>
                                    <input type="radio" id='5_j' name="entry.79522774" value="5" />
                                </VStack>
                                <div>Very satisfied</div>
                            </HStack>
                        </VStack>

                        <VStack align="start" pt={5} pb={5} p={[5, 10, 12]} borderRadius="2xl" backgroundColor="#fff" mb={5} >
                            <label style={{ fontWeight: 'bold' }}>Did you find the KōchClient app to be responsive and fast-loading? Did you experience any delays or lag time when using the app?</label>
                            <HStack>
                                <input type="radio" id='yes_l' name="entry.18562621" value="Yes" />
                                <label for="yes_l">yes</label>
                            </HStack>
                            <HStack>
                                <input type="radio" id='no_l' name="entry.18562621" value="No" />
                                <label for="no_l">no</label>
                            </HStack>
                            <HStack>
                                <input type="radio" id='sometimes_l' name="entry.18562621" value="Sometimes" />
                                <label for="sometimes_l">sometimes</label>
                            </HStack>
                        </VStack>
                        <VStack pt={5} pb={5} >
                            <Button
                                backgroundColor="#fff"
                                mt={[6, 8, 16]}
                                type="submit"
                                color="#000000"
                                fontSize="3xl"
                                p="3vh 5vh"
                                boxShadow="0px 6px 7px rgba(0, 0, 0, 0.15)"
                                borderRadius="15px">
                                SUBMIT
                            </Button>
                        </VStack>
                    </form>
                </Container>
            </section>
        </div>
    )
}

export default ClientFormComponent;