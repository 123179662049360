import React from 'react';
import './PrivacyPolicy.css';

function CookiesPolicyComponent() {
    return (
        <section style={{ paddingBottom: '20vh', paddingLeft: '3vh', paddingRight: '3vh', justifyContent: 'flex-start' }}>
            <h1 className='mainout' style={{ fontSize: '22px', fontWeight: 600 }}>
                Cookie Policy
                <p style={{ fontSize: '11px', fontWeight: 'normal' }}>Last updated: Jan 20, 2024</p>
            </h1>
            <div style={{ fontSize: '13.5px' }}>
                <p>This site uses cookies - small text files placed on your machine to help the site provide a better user experience. In general, cookies retain user preferences, store information for shopping carts, and provide anonymized tracking data to third-party applications like Google Analytics. As a rule, cookies will make your browsing experience better. However, you may prefer to disable cookies on this site and others. The most effective way to do this is to disable cookies in your browser. We suggest consulting the Help section of your browser or looking at <a style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} href='http://www.aboutcookies.org/'>the About Cookies website</a>, which offers guidance for all modern browsers.</p>
            </div>
        </section>
    )
}

export default CookiesPolicyComponent