import React from "react";
import { Button, Divider, HStack, Image, VStack } from "@chakra-ui/react";
import whykoch from "../assets/whykoch.jpg";
import wearekoch from "../assets/wearekoch.jpg";

function AboutComponent() {
  return (
    <>
      <section
        className="hero-section"
        style={{
          alignItems: "center",
          paddingLeft: "0",
          paddingTop: "40vh",
          paddingBottom: "40vh",
        }}
      >
        <h1>
          Hola, We Are{" "}
          <span
            style={{
              background:
                "linear-gradient(90deg, #EDFF00 0%, #EDdf00 61.98%, #EDd000 100%)",
              webkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            KōCH
          </span>
          .
        </h1>
      </section>

      <div style={{ backgroundColor: "black" }}>
        <p
          style={{
            width: "100vw",
            height: "78px",
            backgroundImage: `url(${wearekoch})`,
            backgroundRepeat: "repeat",
          }}
        ></p>
        <div
          style={{
            padding: "10vw",
            paddingTop: "5vw",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Image src={whykoch} alt="whykoch" borderRadius="268px" />
          <VStack color="white" align="start" w={["90%", "50%", "40%"]}>
            <h1 style={{ fontSize: "4em", fontWeight: 700 }}>WHY KōCH?</h1>
            <p style={{ fontSize: "1.5em", lineHeight: "22px" }}>
              KōCH was born from a fantastic idea by Josip Maras, a professional
              personal trainer and owner of a highly influential gym in the
              Toronto area. His work process requires frequent interaction with
              his clients. Josip uses email or social media to communicate with
              his clients, calendar app for training appointments, and Zoom-like
              calling app for online training. After the workout, Josip also
              uses an excel sheet to capture the user's workout data and analyse
              the sheet data before giving feedback to the client. The payment
              methods are also varied, with e-transfer and PayPal being the most
              commonly used methods of payment.
              <br />
              During one interaction with a client, Josip and the user use a
              large number of different kinds of applications, which creates a
              large additional burden for both Josip and the client. And once
              the number of customers increased, managing customer information
              became a problem.
              <br />
              One day Josip suddenly thought, "Can we integrate all the
              functions needed in the industry to reduce the burden on both
              sides?" And that's how KōCH was founded.
            </p>
          </VStack>
        </div>
        <p
          style={{
            width: "100vw",
            height: "78px",
            backgroundImage: `url(${wearekoch})`,
            backgroundRepeat: "repeat",
          }}
        ></p>
      </div>

      <div style={{ padding: "10vw" }}>
        <VStack align="start" w={["90%", "70%", "50%"]}>
          <h1
            style={{
              fontSize: "4em",
              fontWeight: 700,
              textTransform: "uppercase",
              lineHeight: "55px",
            }}
          >
            Hope to receive your feedback.
          </h1>
          <p style={{ fontSize: "2em" }}>
            Your feedbacks will make KōCH progress.
            <br />
            Let's build the KōCH community together!
          </p>
        </VStack>
        <HStack
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          mt={[6, 8, 16]}
          color="white"
          alignItems="end"
        >
          {/* <Button fontSize="20px" size="md" p={"1.2em"} pl={["2em", "3em", "3.84em"]} pr={["2em", "3em", "3.84em"]} bgColor="black" colorScheme="blackAlpha">Take this survey</Button> */}
          <VStack>
            <p style={{ color: "black" }}>If you're a Coach</p>
            <a href="/coachform" style={{ textDecoration: "none" }}>
              <Button
                fontSize="20px"
                size="md"
                p={"1.2em"}
                pl={["2em", "3em", "3.84em"]}
                pr={["2em", "3em", "3.84em"]}
                bgColor="black"
                colorScheme="blackAlpha"
              >
                Take this survey
              </Button>
            </a>
          </VStack>
          <VStack>
            <p style={{ color: "black" }}>If you're a KōCHClient</p>
            <a href="/clientform" style={{ textDecoration: "none" }}>
              <Button
                fontSize="20px"
                size="md"
                p={"1.2em"}
                pl={["2em", "3em", "3.84em"]}
                pr={["2em", "3em", "3.84em"]}
                bgColor="black"
                colorScheme="blackAlpha"
              >
                Take this survey
              </Button>
            </a>
          </VStack>
          <VStack>
            <p style={{ color: "black" }}>If you haven't try KōCH yet</p>
            <a
              href="https://coach.mykoch.app/login"
              style={{ textDecoration: "none" }}
            >
              <Button
                fontSize="20px"
                size="md"
                p={"1.2em"}
                pl={["2em", "3em", "3.84em"]}
                pr={["2em", "3em", "3.84em"]}
                bgColor="black"
                colorScheme="blackAlpha"
              >
                Take this survey
              </Button>
            </a>
          </VStack>
        </HStack>
      </div>
    </>
  );
}

export default AboutComponent;
