import React from 'react';
import './PrivacyPolicy.css';
import { Link } from "react-router-dom";

function MobileTermsofServiceComponent() {
    return (
        <section style={{ paddingBottom: '20vh', paddingLeft: '3vh', paddingRight: '3vh', justifyContent: 'flex-start' }}>
            <h1 className='mainout' style={{ fontSize: '22px', fontWeight: 600 }}>
                Mobile Terms of Service
                <p style={{ fontSize: '11px', fontWeight: 'normal' }}>Last updated: Jan 20, 2024</p>
            </h1>
            <div style={{ fontSize: '13.5px' }}>
                <p>This Mobile Terms of Service Agreement (“<b>Agreement</b>“) is a binding agreement between you (“<b>End User</b>” or “<b>you</b>“) and KOCH Platform Technologies. (“<b>Company</b>“). This Agreement governs your use of KoCH - Train smart and KoCH for Coach on Apple Store and Google Play (including all related documentation, the “<b>Application</b>“). The Application is licensed, not sold, to you.</p>
            </div>

            <div style={{ fontSize: '13.5px' }}>
                <p>BY [CLICKING THE “AGREE” BUTTON / [[DOWNLOADING / INSTALLING / USING] THE APPLICATION], YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT AND THE <Link style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} to="/website-term-of-service">WEBSITE TERMS OF SERVICE</Link>, INCLUDING WITHOUT LIMITATION THEIR PAYMENT PROVISIONS, WHICH ARE EXPRESSLY INCORPORATED INTO AND A PART OF THIS AGREEMENT; (B) REPRESENT THAT YOU ARE 18 YEARS OF AGE OR OLDER; AND (C) ACCEPT THIS AGREEMENT AND THE WEBSITE TERMS OF SERVICE (TO THE FULL AND COMPLETE EXTENT THEY APPLY TO YOU AS A TRAINER, CLIENT, OR OTHERWISE) AND AGREE THAT YOU ARE LEGALLY BOUND BY THEIR RESPECTIVE TERMS. IF YOU DO NOT AGREE TO THE FOREGOING TERMS, DO NOT [DOWNLOAD / INSTALL / USE] THE APPLICATION AND DELETE IT FROM YOUR MOBILE DEVICE.</p>
            </div>

            <div style={{ fontSize: '13.5px' }}>
                <ul style={{ paddingLeft: '2%' }}>
                    <li className='liShape'>License Grant. Subject to the terms of this Agreement, the Company grants you a limited, non-exclusive, and non-transferable license to: 
                        <ul style={{ paddingLeft: '2%' }}>
                            <li style={{ listStyleType: 'circle' }}>download, install, and use the Application for your use on a single mobile device owned or otherwise controlled by you (“<b>Mobile Device</b>“) strictly under the Application's documentation; and</li>
                            <li style={{ listStyleType: 'circle' }}>access, stream, download, and use on such Mobile Device the Content and Services (as defined in Section 5) made available in or otherwise accessible through the Application, strictly by this Agreement and the Terms of Use applicable to such Content and Services as outlined in Section 5.</li>
                        </ul>
                    </li>
                    <li className='liShape'>License Restrictions. You shall not: 
                        <ul style={{ paddingLeft: '2%' }}>
                            <li style={{ listStyleType: 'circle' }}>copy the Application, except as expressly permitted by this license;</li>
                            <li style={{ listStyleType: 'circle' }}>modify, translate, adapt, or otherwise create derivative works or improvements, whether or not patentable, of the Application;</li>
                            <li style={{ listStyleType: 'circle' }}>reverse engineer, disassemble, decompile, decode, or otherwise attempt to derive or gain access to the source code of the Application or any part thereof;</li>
                            <li style={{ listStyleType: 'circle' }}>remove, delete, alter, or obscure any trademarks or any copyright, trademark, patent, or other intellectual property or proprietary rights notices from the Application, including any copy thereof;</li>
                            <li style={{ listStyleType: 'circle' }}>rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer, or otherwise make available the Application, or any features or functionality of the Application, to any third party for any reason, including by completing the Application available on a network where it is capable of being accessed by more than one device at any time; or</li>
                            <li style={{ listStyleType: 'circle' }}>remove, disable, circumvent, or otherwise create or implement any workaround to any copy protection, rights management, or security features in or protecting the Application.</li>
                        </ul>
                    </li>
                    <li className='liShape'>Reservation of Rights. You acknowledge and agree that the Application is provided under license and not sold to you. You do not acquire any ownership interest in the Application under this Agreement or any other rights other than to use the Application by the license granted, and subject to all terms, conditions, and restrictions under this Agreement. Company and its licensors and service providers reserve and shall retain their entire right, title, and interest in and to the Application, including all copyrights, trademarks, and other intellectual property rights therein or relating to it, except as expressly licensed to you in this Agreement.</li>
                    <li className='liShape'>Collection and Use of Your Information. You acknowledge that when you download, install, or use the Application, the Company may use automatic means (including, for example, cookies and web beacons) to collect information about your Mobile Device and about your use of the Application. For details regarding our cookie policy, please click here: <Link style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} to="/copyright">Copyright Policy</Link>. You also may be required to provide certain information about yourself as a condition to download, install, or use the Application or certain of its features or functionality, and the Application may provide you with opportunities to share information about yourself with others. All information the Company collects through or in connection with this Application is subject to our <Link style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} to="/privacy-policy">Privacy Policy</Link>. To the fullest extent allowed by law, by downloading, installing, using, and providing information to or through this Application, you consent to all actions we take concerning your information in compliance with the <Link style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} to="/privacy-policy">Privacy Policy</Link>.</li>
                    <li className='liShape'>Content and Services. The Application may provide access to the Company's website at <a style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} href="https://www.mykoch.app">www.mykoch.app</a> or <a style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} href='https://coach.mykoch.app'>coach.mykoch.app</a> (the “<b>Website</b>”) and products and services accessible thereon, and certain features, functionality, and content accessible on or through the Application may be hosted on the Website (collectively, “<b>Content and Services</b>“). Your access to and use of such Content and Services are governed by the <Link style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} to="/website-term-of-service">Website Terms of Service</Link> and <Link style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} to="/privacy-policy">Privacy Policy</Link>, incorporated herein by this reference. Your access to and use of such Content and Services may require you to acknowledge your acceptance of such Terms of Use and Privacy Policy and/or to register with the Website. Failure to do so may restrict you from accessing or using certain of the Application’s features and functionality. Any violation of our Terms of Service or Privacy Policy will also be a material violation of this Agreement.</li>
                    <li className='liShape'>Geographic Restrictions. The Content and Services are based in the Province of Ontario in Canada. If you access the Content and Services from outside Canada, you are responsible for compliance with applicable local laws.</li>
                    <li className='liShape'>Updates. Company may, from time to time in its sole discretion, develop and provide Application updates, including upgrades, bug fixes, patches, other error corrections, and/or new features (collectively, including related documentation, “<b>Updates</b>”). Updates may also modify or delete certain features and functionality in their entirety. You agree that the Company is not obligated to provide any Updates or to continue providing or enabling any particular features or functionality. Based on your Mobile Device settings, when your Mobile Device is connected to the internet, either: 
                        <ul style={{ paddingLeft: '2%' }}>
                            <li style={{ listStyleType: 'circle' }}>The application will automatically download and install all available Updates, or</li>
                            <li style={{ listStyleType: 'circle' }}>you may receive notice of or be prompted to download and install available Updates.</li>
                        </ul>
                    </li>
                </ul>
                <br />
                <p>You shall promptly download and install all Updates and acknowledge and agree that the Application or portions thereof may not properly operate should you fail to do so. You further agree that all Updates will be deemed part of the Application and be subject to all terms and conditions of this Agreement.</p>
                <ul style={{ paddingLeft: '2%' }}>
                    <li className='liShape'>Third-Party Materials. The Application may display, include, or make available third-party content (including data, information, applications, and other products, services, and/or materials) or provide links to third-party websites or services, including through third-party advertising (“<b>Third-Party Materials</b>“). You acknowledge and agree that the Company is not responsible for Third-Party Materials, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality, or any other aspect thereof. Company does not assume and will not have any liability or responsibility to you or any other person or entity for any Third-Party Materials. Third-party materials and links to them are provided solely as a convenience to you. You (a) access and use any Third-Party Materials entirely at your own risk and subject to such third parties' terms and conditions, and (b) assume all risk related to your access and/or use of Third-Party Materials.</li>
                    <li className='liShape'>Term and Termination.
                        <ul style={{ paddingLeft: '2%' }}>
                            <li style={{ listStyleType: 'circle' }}>The term of the Agreement commences when you [download/install] the Application and will continue in effect until terminated by you or the Company as outlined in Section 9.</li>
                            <li style={{ listStyleType: 'circle' }}>You may terminate this Agreement by deleting the Application and all copies from your Mobile Device.</li>
                            <li style={{ listStyleType: 'circle' }}>The Company may terminate this Agreement without notice if it ceases to support the Application, which the Company may do at its sole discretion. In addition, this Agreement will terminate immediately and automatically without any notice if you violate its terms and conditions.</li>
                            <li style={{ listStyleType: 'circle' }}>Upon termination: 
                                <ul style={{ paddingLeft: '2%' }}>
                                    <li style={{ listStyleType: 'square' }}>all rights granted to you under this Agreement will also terminate and</li>
                                    <li style={{ listStyleType: 'square' }}>you must cease using the Application and delete all copies from your Mobile Device and account.</li>
                                </ul>
                            </li>
                            <li style={{ listStyleType: 'circle' }}>Termination will not limit any of the Company’s rights or remedies at law or in equity.</li>
                        </ul>
                    </li>
                    <li className='liShape'>Disclaimer of Warranties. THE APPLICATION IS PROVIDED TO YOU “AS IS” AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, THE COMPANY, ON ITS BEHALF AND BEHALF OF ITS AFFILIATES AND THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, CONCERNING THE APPLICATION, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT LIMITATION TO THE FOREGOING, THE COMPANY PROVIDES NO WARRANTY OR UNDERTAKING. IT MAKES NO REPRESENTATION OF ANY KIND THAT THE APPLICATION WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE, OR WORK WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS, OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS, OR BE ERROR-FREE, OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED.</li>
                </ul>
                <br />
                <p>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.</p>
                <ul style={{ paddingLeft: '2%' }}>
                    <li className='liShape'>Limitation of Liability. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL THE COMPANY OR ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS OR SERVICE PROVIDERS, HAVE ANY LIABILITY ARISING FROM OR RELATED TO YOUR USE OF OR INABILITY TO USE THE APPLICATION OR THE CONTENT AND SERVICES FOR: 
                        <ul style={{ paddingLeft: '2%' }}>
                            <li style={{ listStyleType: 'circle' }}>PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA, LOSS OF GOODWILL, BUSINESS INTERRUPTION, COMPUTER FAILURE OR MALFUNCTION, OR ANY OTHER CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, OR PUNITIVE DAMAGES.</li>
                            <li style={{ listStyleType: 'circle' }}>DIRECT DAMAGES IN AMOUNTS THAT, IN THE AGGREGATE, EXCEED THE AMOUNT PAID BY YOU FOR THE APPLICATION.</li>
                        </ul>
                    </li>
                </ul>
                <br />
                <p>THE FOREGOING LIMITATIONS WILL APPLY WHETHER SUCH DAMAGES ARISE OUT OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE AND REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE OR COMPANY WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS OF LIABILITY, SO SOME OR ALL OF THE ABOVE LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU.</p>
                <ul style={{ paddingLeft: '2%' }}>
                    <li className='liShape'>Indemnification. You will indemnify, defend, and hold harmless Company and its officers, directors, employees, agents, affiliates, successors, and assigns from and against all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including attorneys’ fees, arising from or relating to your use or misuse of the Application or your breach of this Agreement, including but not limited to the content you submit or make available through the Application.</li>
                    <li className='liShape'>Export Regulation. The Application may be subject to Canadian export control laws, including the Export Control Reform Act and its associated regulations. You shall not, directly or indirectly, export, re-export, or release the Application to, or make the Application accessible from, any jurisdiction or country to which export, re-export, or release is prohibited by law, rule, or regulation. You shall comply with all applicable federal laws, regulations, and rules and complete all required undertakings (including obtaining any necessary export license or other governmental approval) before exporting, re-exporting, releasing, or otherwise making the Application available outside the US.</li>
                    <li className='liShape'>Canadian Government Rights. The Application is commercial computer software, as such term is defined in 48 C.F.R. §2.101. Accordingly, if you are an agency of the Canadian Government or any contractor therefore, you receive only those rights concerning the Application as are granted to all other end users under license, following (a) 48 C.F.R. §227.7201 through 48 C.F.R. §227.7204, to the Department of Defense and their contractors, or (b) 48 C.F.R. §12.212, for all other Canadian Government licensees and their contractors.</li>
                    <li className='liShape'>Severability. If any provision of this Agreement is illegal or unenforceable under applicable law, the remainder of the provision will be amended to achieve as closely as possible the effect of the original term and all other provisions of this Agreement will continue in full force and effect.</li>
                    <li className='liShape'>Governing Law. This Agreement is governed by and construed by the internal laws of the Province of Ontario without giving effect to any choice or conflict of law provision or rule. Any legal suit, action, or proceeding arising from or related to this Agreement or the Application shall be instituted exclusively in Ontario’s federal or Provincial court. You waive all objections to exercising jurisdiction over you by such courts and venue in such courts.</li>
                    <li className='liShape'>Limitation of Time to File Claims. ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE APPLICATION MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.</li>
                    <li className='liShape'>Entire Agreement. This Agreement and any other written policies of the Company constitute the entire agreement between you and the Company to the Application and supersede all prior or contemporaneous understandings and agreements, whether written or oral, for the Application.</li>
                </ul>
                <br />
                <p>Waiver. No failure to exercise and no delay in exercising, on the part of either party, any right or any power hereunder shall operate as a waiver thereof, nor shall any single or partial exercise of any right or power hereunder preclude further exercise of that or any other right. In the event of a conflict between this Agreement and any applicable purchase or other terms, the terms of this Agreement shall govern.</p>
            </div>
        </section>
    )
}

export default MobileTermsofServiceComponent;