import React from 'react';
import './PrivacyPolicy.css';

function CopyrightComponent() {
    return (
        <section style={{ paddingBottom: '20vh', paddingLeft: '3vh', paddingRight: '3vh', justifyContent: 'flex-start' }}>
            <h1 className='mainout' style={{ fontSize: '22px', fontWeight: 600 }}>
                Copyright
                <p style={{ fontSize: '11px', fontWeight: 'normal' }}>Last updated: Jan 20, 2024</p>
            </h1>
            <div style={{ fontSize: '13.5px' }}>
                <p><b>Reporting Claims of Copyright Infringement</b>. We take claims of copyright infringement seriously. We will respond to notices of alleged copyright infringement that comply with applicable law if you believe any materials accessible on or from the <a style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} href="https://www.mykoch.app">www.mykoch.app</a> or <a style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }} href='https://coach.mykoch.app'>coach.mykoch.app</a> website (the “<b>Website</b>“) infringes your copyright, you may request removal of those materials (or access to them) from the Website by submitting written notification to our copyright agent designated below. By the Online Copyright Infringement Liability Limitation Act of the Digital Millennium Copyright Act (17 U.S.C. § 512) (“<b>DMCA</b>“), the written notice (the “<b>DMCA Notice</b>“) must include substantially the following:</p>
                <ul style={{ paddingLeft: '2%' }}>
                    <li className='liShape'>Your physical or electronic signature.</li>
                    <li className='liShape'>Identify the copyrighted work you believe to have been infringed or, if the claim involves multiple works on the Website, a representative list of such works.</li>
                    <li className='liShape'>Identification of the material you believe to be infringing in a sufficiently precise manner to allow us to locate that material.</li>
                    <li className='liShape'>Adequate information by which we can contact you (including your name, postal address, telephone number, and, if available, email address).</li>
                    <li className='liShape'>A statement that you have a good faith belief that use of the copyrighted material is not authorized by the copyright owner, its agent, or the law.</li>
                    <li className='liShape'>A statement that the information in the written notice is accurate.</li>
                    <li className='liShape'>A statement, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.</li>
                </ul>
                <br />
                <p>Our designated copyright agent to receive DMCA Notices is:</p>
                <p>Josip Maras<br />
                    KOCH Platform Technologies.<br />
                    42 Scarsdale Rd #Unit - 2, Toronto, ON, Canada M3B 2R7<br />
                    support@mykoch.app</p>
                <p>If you fail to comply with all of the requirements of Section 512(c)(3) of the DMCA, your DMCA Notice may not be effective.</p>
                <p>Please be aware that if you knowingly materially misrepresent that material or activity on the Website infringes your copyright, you may be held liable for damages (including costs and attorneys' fees) under Section 512(f) of the DMCA.</p>
            </div>

            <div style={{ fontSize: '13.5px' }}>
                <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>Counter-Notification Procedures</h2>
                <p>Suppose you believe that material you posted on the Website was removed or access to it was disabled by mistake or misidentification. In that case, you may file a counter-notification with us (a “Counter-Notice“) by submitting a written notification to our copyright agent designated [above/below]. According to the DMCA, the Counter-Notice must include substantially the following:</p>
                <ul style={{ paddingLeft: '2%' }}>
                    <li className='liShape'>Your physical or electronic signature.</li>
                    <li className='liShape'>An identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access disabled.</li>
                    <li className='liShape'>Adequate information by which we can contact you (including your name, postal address, telephone number, and, if available, email address).</li>
                    <li className='liShape'>A statement under penalty of perjury by you that you have a good faith belief that the material identified above was removed or disabled due to a mistake or misidentification of the material to be removed or disabled.</li>
                    <li className='liShape'>A statement that you will consent to the jurisdiction of the Federal District Court for the judicial district in which your address is located (or if you reside outside Canada for any judicial district in which the Website may be found) and that you will accept service from the person (or an agent of that person) who provided the Website with the complaint at issue.</li>
                </ul>
                <br />
                <p>Our designated agent to receive Counter-Notices is:</p>
                <p>Josip Maras<br />
                    KOCH Platform Technologies.<br />
                    42 Scarsdale Rd #Unit - 2, Toronto, ON, Canada M3B 2R7<br />
                    support@mykoch.app</p>
                <p>The DMCA allows us to restore the removed content if the party filing the original DMCA Notice does not file a court action against you within ten business days of receiving the copy of your Counter-Notice.</p>
                <p>Please be aware that if you knowingly materially misrepresent that material or activity on the Website was removed or disabled by mistake or misidentified, you may be liable for damages (including costs and attorneys' fees) under Section 512(f) of the DMCA.</p>
            </div>

            <div style={{ fontSize: '13.5px' }}>
                <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>Repeat Infringers</h2>
                <p>It is our policy in appropriate circumstances to disable and/or terminate the accounts of users who are repeat infringers.</p>
            </div>

        </section>
    )
}

export default CopyrightComponent