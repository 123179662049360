import React from 'react'
import { Image, VStack } from '@chakra-ui/react';

function CoachTutorialComponent() {
  return (
    <div>
      <section className="hero-section" style={{ padding: '20vh 0 0 0', alignItems: 'center' }}>
        <h1>KōCH Beta - Coach Tutorial</h1>
      </section>

      <div style={{ padding: '3vw 7vw 0 7vw', fontSize: '4em', fontWeight: 600 }}>
        <h1 style={{ paddingBottom: '2vh' }}>1. Coach Portal - Sign Up Process</h1>
        <div className='iframe-container'>
          <iframe src="https://www.youtube.com/embed/pfLjsEn86EE?autoplay=1" title="Signup Process for Koch web portal  | KochApp | Fitness App" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>

      <div style={{ padding: '7vw 7vw 0 7vw', fontSize: '4em', fontWeight: 600 }}>
        <h1 style={{ paddingBottom: '2vh' }}>2. Coach Portal - KYC and Stripe Integrations</h1>
        <div className='iframe-container'>
          <iframe src="https://www.youtube.com/embed/bP5XXd40E6E" title="KYC and Stripe Integrations | KochApp | Coach Web Portal" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>

      <div style={{ padding: '7vw 7vw 0 7vw', fontSize: '4em', fontWeight: 600 }}>
        <h1 style={{ paddingBottom: '2vh' }}>3. Coach Portal - Create Package and Service</h1>
        <div className='iframe-container'>
          <iframe src="https://www.youtube.com/embed/zJAYsB94ido" title="Create Package and Service | KochApp | Coach Web Portal" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>

      <div style={{ padding: '7vw 7vw 0 7vw', fontSize: '4em', fontWeight: 600 }}>
        <h1 style={{ paddingBottom: '2vh' }}>4. Coach Portal - Edit Package and Service</h1>
        <div className='iframe-container'>
          <iframe src="https://www.youtube.com/embed/4qA42qyvmFQ" title="Edit Package and Service | KochApp | Coach Web Portal" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>

      <div style={{ padding: '7vw 7vw 0 7vw', fontSize: '4em', fontWeight: 600 }}>
        <h1 style={{ paddingBottom: '2vh' }}>5. Coach Portal - Add New Client</h1>
        <div className='iframe-container'>
          <iframe src="https://www.youtube.com/embed/308mEbXwxeI" title="Add New Client | KochApp | Coach Web Portal" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>

      <div style={{ padding: '7vw', fontSize: '4em', fontWeight: 600 }}>
        <h1 style={{ paddingBottom: '2vh' }}>6. Coach Portal - Schedule and Availability</h1>
        <div className='iframe-container'>
          <iframe src="https://www.youtube.com/embed/fXg69X-aqdY" title="Schedule and Availability | KoCHApp | Coach Web Platform" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  )
}

export default CoachTutorialComponent