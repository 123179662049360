import React from 'react'
import { Image, VStack } from '@chakra-ui/react';

function ClientTutorialComponent() {
    return (
        <div>
            <section className="hero-section" style={{ padding: '20vh 0 0 0', alignItems: 'center' }}>
                <h1>KōCH Beta - Client App Tutorial</h1>
            </section>

            <div style={{ padding: '3vw 7vw 0 7vw', fontSize: '4em', fontWeight: 600 }}>
                <h1 style={{ paddingBottom: '2vh' }}>1. Client App - Service and Package Purchase</h1>
                <div className='iframe-container'>
                    <iframe src="https://www.youtube.com/embed/in-y5Hxejsg?autoplay=1" title="Client App Package Purchase | KochApp | Client iOS App" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>

            <div style={{ padding: '7vw', fontSize: '4em', fontWeight: 600 }}>
                <h1 style={{ paddingBottom: '2vh' }}>2. Client App - Appointment Booking</h1>
                <div className='iframe-container'>
                    <iframe src="https://www.youtube.com/embed/tq4n1Bu1dhk" title="Appointment Booking | KoCHApp | Client iOS App" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    )
}

export default ClientTutorialComponent