import React from 'react'
import { Image, Input, Button, Container, HStack, VStack } from '@chakra-ui/react';

function JoinBetaComponent() {
    return (
        <div>
            <section className="hero-section" style={{ padding: '20vh 0 10vh 0', alignItems: 'center', backgroundColor: '#eaeaea' }}>
                <h1>KōCH Beta Sign Up </h1>
                <Container maxW='7xl' fontSize="2xl">
                    <form action="https://docs.google.com/forms/u/2/d/e/1FAIpQLSfdaTOp99cJYda2r-J9fbwHa3MGKjT2qUvtD5uFL4s1NpzIlw/formResponse" method='POST'>
                        <VStack align="start" pt={5} pb={5} p={[5, 10, 12]} borderRadius="2xl" backgroundColor="#fff" mb={5} >
                            <label style={{ fontWeight: 'bold' }}>Your Name<span class="color-superscript" /></label>
                            <Input size="xl" variant="flushed" name="entry.1007023694" required placeholder='your name' />
                        </VStack>

                        <VStack align="start" pt={5} pb={5} p={[5, 10, 12]} borderRadius="2xl" backgroundColor="#fff" mb={5} >
                            <label style={{ fontWeight: 'bold' }}>Phone Number</label>
                            <Input size="xl" variant="flushed" type="tel" name="entry.1232403826" placeholder='Phone Number' />
                        </VStack>

                        <VStack align="start" pt={5} pb={5} p={[5, 10, 12]} borderRadius="2xl" backgroundColor="#fff" mb={5} >
                            <label style={{ fontWeight: 'bold' }}>Email<span class="color-superscript" /></label>
                            <Input size="xl" variant="flushed" type="email" name="entry.367925709" required placeholder='Email' />
                        </VStack>
                        {/* <Input hidden name='entry.1665930231' value='Coach' /> */}

                        <VStack align="start" pt={5} pb={5} p={[5, 10, 12]} borderRadius="2xl" backgroundColor="#fff" mb={5} >
                            <label style={{ fontWeight: 'bold' }}>Preferred Contact Method<span class="color-superscript" /></label>
                            <HStack>
                                <input type="radio" id='phone' name="entry.2043667928" value="Phone Number" />
                                <label for="phone">Phone Number</label>
                            </HStack>
                            <HStack>
                                <input type="radio" id='email' name="entry.2043667928" value="Email" />
                                <label for="email">Email</label>
                            </HStack>
                            <HStack>
                                <input type="radio" id='both' name="entry.2043667928" value="Both Phone and Email" defaultChecked />
                                <label for="both">Both</label>
                            </HStack>
                        </VStack>

                        <VStack align="start" pt={5} pb={5} p={[5, 10, 12]} borderRadius="2xl" backgroundColor="#fff" mb={5} >
                            <label style={{ fontWeight: 'bold' }}>What is your Instagram handle?<br />
                                <span style={{ fontWeight: 'normal' }}>
                                    We'll be sure to follow you there and keep you updated with our latest progress.
                                </span></label>
                            <Input size="xl" variant="flushed" name="entry.443372126" placeholder='your answer' />
                        </VStack>


                        <VStack align="start" pt={5} pb={5} p={[5, 10, 12]} borderRadius="2xl" backgroundColor="#fff" mb={5} >
                            <label style={{ fontWeight: 'bold' }}>Questions and Comments <br />
                                <span style={{ fontWeight: 'normal' }}>
                                    Please let us know if you have questions or suggestions regarding online fitness training that we can add to the app. We appreciate your time. Thank you.
                                </span>
                            </label>
                            <Input size="xl" variant="flushed" name="entry.1489519013" placeholder='your answer' />
                        </VStack>

                        <VStack pt={5} pb={5} >
                            <Button
                                backgroundColor="#fff"
                                mt={[6, 8, 16]}
                                type="submit"
                                color="#000000"
                                fontSize="3xl"
                                p="3vh 5vh"
                                boxShadow="0px 6px 7px rgba(0, 0, 0, 0.15)"
                                borderRadius="15px">
                                SUBMIT
                            </Button>
                        </VStack>
                    </form>
                </Container>
            </section>
        </div>
    )
}

export default JoinBetaComponent;